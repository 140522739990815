<template>
    <div>
        <v-form @submit.prevent="send" :disabled="loading" ref="form">
            <v-card :loading="loading">
                <v-toolbar flat>
                    <v-toolbar-title>Наименование</v-toolbar-title>
                    <v-spacer />
                    <v-btn icon :to="{ name: 'CatList' }" exact>
                        <v-icon>mdi-arrow-up</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Имя"
                                v-model="name.ru"
                                :rules="req"
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                label="Имя [En]"
                                v-model="name.en"
                                :rules="req"
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                label="Алиас"
                                v-model="slug"
                                clearable
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        text
                        color="primary"
                        type="submit"
                        :disabled="loading"
                    >
                        Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
        <v-card class="mt-8" :loading="loadingTag">
            <v-toolbar flat>
                <v-toolbar-title>Теги</v-toolbar-title>
                <v-spacer />
                <v-btn icon color="primary" @click="addTag">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-toolbar>
            <v-list>
                <v-form
                    v-for="(t, i) in tags"
                    :key="t.id"
                    :disabled="loadingTag"
                    :ref="`tag-${t.id}`"
                    @submit.prevent="sendTag(t.id)"
                >
                    <v-list-item>
                        <v-list-item-action>
                            <v-btn
                                icon
                                :disabled="i == 0 || loadingTag"
                                @click="saveSort(false, t.id)"
                            >
                                <v-icon>mdi-arrow-up</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                :disabled="i == tags.length - 1 || loadingTag"
                                @click="saveSort(true, t.id)"
                            >
                                <v-icon>mdi-arrow-down</v-icon>
                            </v-btn>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        label="Имя"
                                        :rules="req"
                                        v-model="t.name.ru"
                                    />
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        label="Имя [En]"
                                        :rules="req"
                                        v-model="t.name.en"
                                    />
                                </v-col>
                            </v-row>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn
                                text
                                color="primary"
                                type="submit"
                                :disabled="loadingTag"
                            >
                                Сохранить
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-form>
            </v-list>
        </v-card>
    </div>
</template> 

<script>
export default {
    name: "CatOne",
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data: () => ({
        loading: false,
        loadingTag: false,
        slug: null,
        name: {
            ru: null,
            en: null,
        },
        tags: [],
    }),
    computed: {
        req: () => [(v) => !!v || "Заполните поле"],
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            this.loading = true;
            const { name, tags, slug } = await this.$admin(
                `/category/${this.id}`
            );
            this.name = name;
            this.slug = slug;
            this.tags = tags;
            this.loading = false;
        },
        async send() {
            const v = this.$refs.form.validate();
            if (!v) {
                return;
            }
            this.loading = true;
            const { slug } = await this.$admin(`/category/${this.id}`, {
                method: "PATCH",
                body: {
                    name: this.name,
                    slug: this.slug,
                },
            });
            this.slug = slug;
            this.loading = false;
        },
        async sendTag(id) {
            const v = this.$refs[`tag-${id}`][0].validate();
            if (!v) {
                return;
            }
            let tag;
            for (let t of this.tags) {
                if (t.id == id) {
                    tag = t;
                    break;
                }
            }
            this.loadingTag = true;
            await this.$admin(`/category/${this.id}`, {
                method: "POST",
                body: {
                    id,
                    name: tag.name,
                },
            });
            this.loadingTag = false;
        },
        saveSort(dir, id) {
            const list = [...this.tags];
            for (let i = 0; i < list.length; i++) {
                if (list[i].id == id) {
                    let target = list[i];
                    let another = list[dir ? i + 1 : i - 1];
                    [list[i], list[dir ? i + 1 : i - 1]] = [another, target];
                    this.$admin(`/category/${this.id}/sort`, {
                        method: "PATCH",
                        body: [
                            { id, sort: dir ? i + 1 : i - 1 },
                            { id: another.id, sort: i },
                        ],
                    });
                    break;
                }
            }
            this.tags = list;
        },
        async addTag() {
            const ru = await this.$prompt({
                text: "Введите значение",
            });
            if (!ru) {
                return false;
            }
            const en = await this.$prompt({
                text: "Введите значение [En]",
            });
            if (!en) {
                return false;
            }
            this.loadingTag = true;
            const { id } = await this.$admin(`/category/${this.id}`, {
                method: "POST",
                body: {
                    id: -1,
                    name: { ru, en },
                    sort: this.tags.length,
                },
            });
            this.tags.push({
                id,
                name: {
                    ru,
                    en,
                },
            });
            this.loadingTag = false;
        },
    },
};
</script>
